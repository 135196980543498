<template>
  <div class="contact">
    <PageBanner
      :firstword="'The future'"
      :keyword="'starts'"
      :secondword="'today.'"
      :img="'contact.jpg'"
      :subtext="''"
    />
    <div class="contact-content">
      <div class="form-container">
        <form sumbit.prevent>
          <input type="text" placeholder="Asunto" />
          <input type="text" placeholder="Nombre Completo" />
          <input type="text" placeholder="Email" />
          <input type="text" placeholder="Teléfono" />
          <textarea
            name="Message"
            id=""
            cols="30"
            rows="15"
            placeholder="Mensaje"
          ></textarea>
          <input class="button-submit" type="submit" />
        </form>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import PageBanner from "@/components/page-banners.vue";
export default {
  name: "Contact",
  components: {
    PageBanner,
  },
};
</script>

<style lang="scss">
.contact {
  .contact-content {
    height: 1280px;
    background-color: #222ac3;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-container {
      width: 1640px;
      height: 880px;
      background-color: white;
      border-radius: 25px;
      max-width: 1280px;
      form {
        display: flex;
        flex-direction: column;
        padding: 40px 30px;
        align-items: center;
        justify-content: center;
        input {
          width: 100%;
          margin-bottom: 30px;
          border: 1px solid #d0d0d0;
          height: 70px;
          border-radius: 35px;
          padding: 20px 30px;
          &:focus {
            border-color: #222ac3;
          }
        }
        textarea {
          width: 100%;
          margin-bottom: 30px;
          border-radius: 35px;
          border: 1px solid #d0d0d0;
          box-shadow: none;
          overflow: auto;
          outline: none;
          padding: 20px 30px;
        }
        .button-submit {
          width: 260px;
          height: 75px;
          background-color: #222ac3;
          border-radius: 40px;
          text-align: center;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
